import { useCallback, useMemo } from "react"
import ReactServer from "react-dom/server"

import * as Highcharts from "highcharts"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsExport from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"

import { THome } from "src/data/homes/types/homeTypes"
import { TClockType, TUser } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { colorScale, interpolateColors, mColors } from "src/ui/colors"
import { ChartTooltip } from "src/ui/Graphs/ChartTooltip"
import {
  TBarChartMultiSeriesData,
  TBarChartSingleSeriesData,
} from "src/ui/Graphs/graphTypes"
import { formatDate } from "src/utils/l10n"

HighchartsExport(Highcharts)
HighchartsExportData(Highcharts)

export type BarChartProps = {
  canExport?: boolean
  zoom?: "x" | "xy" | "y"
  timezone: THome["timezone"]
  clockType: TClockType
  showLegend?: boolean
  stackSeries?: boolean
  multiSeriesColors?: string[]
  borderRadius?: number
  seriesHoverBrightness?: number
  interactive?: boolean
  xAxisOptions?: Highcharts.XAxisOptions
  yAxisOptions?: Highcharts.YAxisOptions
  tooltip: {
    disabled?: boolean
    unit?: string
    decimals?: number
    borderRadius?: Highcharts.TooltipOptions["borderRadius"]
    formatter?: (context: {
      date: Date
      value: number
      min?: number
      max?: number
      points?: Highcharts.TooltipFormatterContextObject["points"]
    }) => React.ReactElement
  }
} & (
  | {
      dataSeriesType: "single"
      data: TBarChartSingleSeriesData[]
    }
  | {
      dataSeriesType: "multi"
      data: TBarChartMultiSeriesData[]
    }
)

export function BarChart({
  data,
  canExport = false,
  zoom = "x",
  tooltip,
  timezone,
  clockType,
  showLegend = true,
  stackSeries = false,
  dataSeriesType = "single",
  multiSeriesColors,
  borderRadius,
  seriesHoverBrightness,
  interactive = false,
  xAxisOptions,
  yAxisOptions,
}: BarChartProps) {
  const { t } = useTranslate()

  const series: Highcharts.Options["series"] = useMemo(() => {
    if (dataSeriesType === "single") {
      const singleData = data as TBarChartSingleSeriesData[]
      const s: Highcharts.Options["series"] = [
        {
          type: "column",
          showInLegend: false,
          data: singleData,
          borderRadius: borderRadius ?? 6,
          color: mColors.primary,
          groupPadding: 0,
        },
      ]
      return s
    } else if (dataSeriesType === "multi") {
      const multiData = data as TBarChartMultiSeriesData[]

      return multiData.map(({ data, name, borderColor }, index) => {
        return {
          type: "column",
          showInLegend: true,
          data: data,
          borderRadius: borderRadius ?? 0,
          color: getMultiDataColor({
            colors: multiSeriesColors,
            index,
            length: multiData.length,
          }),
          groupPadding: 0.3,
          borderColor,
          name: name,
        }
      })
    }
  }, [borderRadius, multiSeriesColors, data, dataSeriesType])

  const tooltipFormatter = useCallback(
    (context: Highcharts.TooltipFormatterContextObject) => {
      if (!context.x || !context.y) return false

      if (tooltip.formatter) {
        return ReactServer.renderToString(
          tooltip.formatter({
            date: new Date(context.x),
            value: context.y,
            points: context.points,
          })
        )
      }

      const date = formatDate({
        date: new Date(context.x).toISOString(),
        clockType,
        timezone,
      })
      const decimals = tooltip.decimals ?? 1

      const contentLabel = t(langKeys.value)

      return ReactServer.renderToString(
        <ChartTooltip
          date={date}
          label={contentLabel}
          value={context.y.toFixed(decimals)}
          unit={tooltip.unit ?? ""}
        />
      )
    },
    [clockType, t, timezone, tooltip]
  )

  const options = useMemo<Highcharts.Options>(
    () => ({
      accessibility: { enabled: false },
      credits: { enabled: false },
      title: { text: "" },
      xAxis: {
        labels: { style: axisLabelStyle },
        lineColor: mColors.divider,
        tickColor: mColors.divider,
        title: { text: "" },
        type: "datetime",
        tickAmount: 8,
        dateTimeLabelFormats: getXAxisDateTimeLabelFormat(clockType),
        crosshair: interactive
          ? {
              color: mColors.primary,
              dashStyle: "ShortDot",
              width: 2,
              zIndex: 3,
            }
          : false,
        ...xAxisOptions,
      },
      yAxis: {
        labels: { style: axisLabelStyle },
        title: { text: "" },
        endOnTick: true,
        startOnTick: true,
        allowDecimals: false,
        type: "linear",
        ...yAxisOptions,
      },
      time: { timezone },
      chart: {
        type: "column",
        zooming: { type: zoom },
      },
      exporting: {
        enabled: canExport,
        csv: {},
      },
      legend: {
        enabled: showLegend,
        align: "center",
        verticalAlign: "bottom",
        itemStyle: {
          color: mColors.textTertiary,
          fontFamily: "'Figtree', sans-serif",
          fontSize: "12px",
        },
      },
      plotOptions: {
        column: {
          stacking: stackSeries ? "normal" : undefined,
        },
        series: {
          states: {
            hover: {
              enabled: interactive,
              brightness: seriesHoverBrightness ?? 0.1,
            },
          },
        },
      },
      series,
      tooltip: {
        enabled: !tooltip.disabled,
        useHTML: true,
        padding: 0,
        shared: true,
        shadow: false,
        outside: true,
        borderRadius: tooltip.borderRadius ?? undefined,
        formatter() {
          return tooltipFormatter(this)
        },
      },
    }),
    [
      clockType,
      timezone,
      zoom,
      canExport,
      showLegend,
      stackSeries,
      series,
      tooltip.disabled,
      tooltipFormatter,
      tooltip.borderRadius,
      seriesHoverBrightness,
      interactive,
      xAxisOptions,
      yAxisOptions,
    ]
  )

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

const axisLabelStyle: Highcharts.CSSObject = {
  fontFamily: "'Figtree', sans-serif",
  fontSize: "12px",
  color: mColors.textTertiary,
}

/** https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats */
const defaultDateTimeLabelFormat = {
  millisecond: "%H:%M:%S.%L",
  second: "%H:%M:%S",
  minute: "%H:%M",
  hour: "%H:%M",
  day: "%e %b",
  week: "%e %b",
  month: "%b '%y",
  year: "%Y",
} as const

function getXAxisDateTimeLabelFormat(
  clockType: TUser["clock_type"]
): Highcharts.AxisDateTimeLabelFormatsOptions {
  const use12hClock = clockType === "12"
  if (use12hClock) {
    return {
      ...defaultDateTimeLabelFormat,
      hour: `%I:%M %p`,
      day: "%a %e %b",
    }
  }
  return defaultDateTimeLabelFormat
}

function getMultiDataColor({
  colors,
  index,
  length,
}: {
  colors?: string[]
  index: number
  length: number
}): string | undefined {
  const startColor = colorScale.gaff[300]
  const endColor = colorScale.gaff[500]
  const interpolatedColors = interpolateColors(startColor, endColor, length)

  if ((colors?.length ?? 0) > 0) {
    return colors?.[index]
  } else {
    return interpolatedColors[index]
  }
}
